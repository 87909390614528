import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { Features, useIsABVariant } from "@finn/ua-featureflags";
import { useCurrentLocale } from "@finn/ui-utils";
import get from "lodash/get";
import { useMemo } from "react";
export var useMenuData = function(cosmicPageData) {
    var isJobAutoExp = useIsABVariant(Features.ExpJobAutoLink);
    var isDE = useCurrentLocale().isDE;
    return useMemo(function() {
        var ref;
        var newLinks = _to_consumable_array(cosmicPageData.metadata.links);
        var hasJobAutoLink = newLinks.find(function(item) {
            return item.metadata.href === "/b2b/job-auto";
        });
        if (isJobAutoExp && !hasJobAutoLink && isDE) {
            var jobAutoLink = {
                _id: "",
                slug: "",
                title: "",
                content: "",
                type_slug: "link",
                status: "",
                locale: "",
                metadata: {
                    href: "/b2b/job-auto",
                    label: "JobAuto"
                }
            };
            newLinks.splice(2, 0, jobAutoLink);
        }
        var links = newLinks.map(function(link) {
            return link.metadata;
        });
        var ribbon = cosmicPageData.metadata.ribbon;
        var userAccountLinks = ((ref = cosmicPageData.metadata.user_accounts) === null || ref === void 0 ? void 0 : ref.navbar_user_account_links) || {};
        userAccountLinks.dropdownOptions = get(cosmicPageData, "metadata.user_accounts.dropdown_options");
        userAccountLinks.helpLink = get(cosmicPageData, "metadata.help_link.metadata");
        return {
            ribbon: ribbon,
            links: links,
            userAccountLinks: userAccountLinks
        };
    }, [
        cosmicPageData,
        isDE,
        isJobAutoExp
    ]);
};
